import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Datenschutzbestimmungen",
  "description": "So können Sie die Datenschutzbestimmungen der App anpassen.",
  "author": "Jochen Ritscher",
  "categories": ["settings"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Klicken Sie im Backend auf Einrichtung > Einstellungen.  Wählen Sie den Untermenüpunkt "Einstellungen" und klicken Sie auf "bearbeiten".
Im Folgenden Dialog können Sie die im Bewerbungsprozess angezeigten Datenschutzbestimmungen bearbeiten.
Bitte beachten Sie, dass jegliche Änderungen die die Aufbewahrung, Löschung und Auftragsdatenverarbeitung betreffen mit Ihrem Ansprechpartner bei inriva abgestimmt sein müssen und dass Sie Änderungen auch allen aktiven Bewerbern schriftlich anzeigen müssen.`}</p>
    <img src="/images/settings/gdpr.jpg" alt="gdpr" style={{
      "width": "100%"
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      